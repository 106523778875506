import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';

import CustomForm from 'src/components/form';
import {
  defaultScheduleData,
  preferredScheduleCode,
  requiredField,
  scheduleMessage,
  successMessage,
} from 'src/lib/constants';
import Box from 'src/components/Box';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import { SAVE_STAFF_LOCATION_DATA } from 'src/store/types';
import useCRUD from 'src/hooks/useCRUD';
import { dateFormatterDayjs, showSnackbar } from 'src/lib/utils';
import LoadingButton from 'src/components/CustomButton/loadingButton';
import PageContent from 'src/components/PageContent';
import PageHeader from 'src/components/PageHeader';
import ScheduleTable from './scheduleTable';
import {
  WiredLocationField,
  WiredMasterField,
} from 'src/wiredComponent/Form/FormFields';
import { isEmpty} from 'lodash';
import { useSelector } from 'react-redux';

const AddLocation = ({
  selectedLocation,
  onCancel,
  fetchLocations,
  staffId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const[render , setRerender] = useState(false)
  const locationData = useSelector(
    (state) =>
      state?.crud?.get('wired-select-locationId')?.get('read')?.get('data')
        ?.results
  );

  const showLeadInterval = (data) => {
    if (data?.leadDays === '0_day') {
      return { hide: false };
    }
    return { hide: true };
  };
 
  const LocationFormGroups = useMemo(
    () => [
      {
        ...WiredLocationField({
          name: 'locationId',
          label: 'Location',
          colSpan: 1,
          placeholder: 'Select',
          filter: { limit: 20 },
          required: requiredField,
          labelAccessor: 'name',
          valueAccessor: 'id',
          disabled: !isEmpty(selectedLocation),
        }),
      },
      {
        ...WiredMasterField({
          code: 'appointment_confirmation',
          filter:{limit:20},
          name: 'appointmentConfirmation',
          label:"Appointment confirmation",
          labelAccessor:'name',
          valueAccessor:'code',
          colSpan:1,
          required: requiredField,
          cache:false,
    
        }),
      },
      {
        ...WiredMasterField({
          code: 'appointment_interval',
          filter:{limit:20},
          name: 'appointmentInterval',
          label:"Appointment interval",
          labelAccessor:'name',
          valueAccessor:'code',
          colSpan:1,
          required: requiredField,
          cache:false,
    
        }),
      },
      {
        ...WiredMasterField({
          code: 'lead_days',
          filter:{limit:20},
          name: 'leadDays',
          label:"Lead days",
          labelAccessor:'name',
          valueAccessor:'code',
          colSpan:1,
          required: requiredField,
          cache:false,
    
        }),
      },
      {
        ...WiredMasterField({
          code: 'lead_interval',
          filter:{limit:20},
          name: 'leadInterval',
          label:"Lead Time",
          labelAccessor:'name',
          valueAccessor:'code',
          colSpan:1,
          cache:false,
          dependencies: {
            keys: ['leadDays'],
            calc: showLeadInterval,
          },
        }),
      }
    ],
    [selectedLocation]
  );
 

  const getInitialValue = useCallback(() => {
    if (selectedLocation && selectedLocation?.schedule?.length) {
      return cloneDeep(selectedLocation?.schedule);
    } 

    return cloneDeep(defaultScheduleData);
  }, [selectedLocation]);

  const schedule = useRef(getInitialValue());

  const [
    saveLocationRes,
    ,
    saveLocationLoading,
    saveLocation,
    clearSaveLocationRes,
  ] = useCRUD({
    id: SAVE_STAFF_LOCATION_DATA,
    url: API_URL.staffLocation,
    type: selectedLocation ? REQUEST_METHOD.update : REQUEST_METHOD.post,
  });

  const onClose = useCallback(() => {
    clearSaveLocationRes();
    onCancel();
  }, [clearSaveLocationRes, onCancel]);

  const defaultValue = useMemo(
    () =>
      selectedLocation
        ? {
            locationId: selectedLocation?.locationId,
            leadDays: selectedLocation?.leadDays,
            appointmentConfirmation: selectedLocation?.appointmentConfirmation,
            appointmentInterval: selectedLocation?.appointmentInterval,
            leadInterval: selectedLocation?.leadInterval,
          }
        : {},
    [selectedLocation]
  );

  const form = useForm({ mode: 'onChange' });
  
  const {watch , handleSubmit } = form;
  
  useEffect(() => {
    const subscription = watch((value, { name ,type }) => {
      if(name === 'locationId' && type ){
        const selectedLocationSchedule = locationData?.find(location => location?.id === value?.locationId);
        schedule.current = cloneDeep(selectedLocationSchedule?.schedule || defaultScheduleData)
        setRerender((prev)=>!prev)
      }
    });
    return () => subscription.unsubscribe();
  }, [locationData, watch]);
  useEffect(()=>{
    if(!selectedLocation){}
  },[selectedLocation])

  const handleCheckboxChange = useCallback(
    (index) => (event) => {
      setChecked((value) => !value);
      schedule.current[index].isClosed = event.target.checked;
    },
    [schedule]
  );

  const handleStartTime = useCallback(
    (index) => (newValue) => {
      const endTime = schedule.current[index].endHrs;
      const startTime = dateFormatterDayjs(newValue, 'HH:mm');
      if (!startTime || !endTime) {
        setError(true);
      } else if (startTime > endTime) {
        setError(true);
      } else {
        setError(false);
      }
      schedule.current[index].startHrs = startTime;
    },
    [schedule]
  );

  const handleEndTime = useCallback(
    (index) => (newValue) => {
      schedule.current[index].endHrs = dateFormatterDayjs(newValue, 'HH:mm');
    },
    [schedule]
  );

  const handleSave = useCallback(
    (data) => {
      const {
        locationId,
        appointmentInterval,
        leadDays,
        leadInterval,
        appointmentConfirmation,
      } = data;
       

      const payload = {
        locationId,
        staffId,
        schedule: schedule.current,
        leadDays,
        appointmentConfirmation,
        leadInterval,
        appointmentInterval,
        ...(!selectedLocation && { preferredScheduleCode: preferredScheduleCode.DEFAULT_SCHEDULE }),
      };

      if (selectedLocation) {
        const initialData = {
          locationId: selectedLocation?.locationId,
          leadDays: selectedLocation?.leadDays,
          appointmentInterval: selectedLocation?.appointmentInterval,
          leadInterval: selectedLocation?.leadInterval,
          appointmentConfirmation: selectedLocation?.appointmentConfirmation,
          preferredScheduleCode: selectedLocation?.preferredSchedule,
          schedule: cloneDeep(
            selectedLocation?.schedule?.length
              ? selectedLocation?.schedule
              : defaultScheduleData
          ),
        };
        if (!isEqual(payload, initialData)) {
          saveLocation(payload, `/${selectedLocation.id}`);
        } else {
          showSnackbar({
            message: 'No changes found',
            severity: 'error',
          });
        }
        return;
      }

      saveLocation({
        data: payload,
      });
    },
    [staffId, error, selectedLocation, saveLocation]
  );

  useEffect(() => {
    if (saveLocationRes) {
      showSnackbar({
        message: selectedLocation
          ? successMessage.update
          : successMessage.create,
        severity: 'success',
      });
      fetchLocations();
      onClose();
    }
  }, [fetchLocations, onClose, saveLocationRes, selectedLocation]);

  return (
    <PageContent disableGutters style={{ overflowY: 'scroll' }}>
      <PageHeader
        title="Location Details"
        showBackIcon
        onPressBackIcon={onClose}
      />
      <div>
        <CustomForm
          formGroups={LocationFormGroups}
          columnsPerRow={2}
          defaultValue={defaultValue}
          form={form}
        />
      </div>
      <ScheduleTable
        handleStartTime={handleStartTime}
        handleEndTime={handleEndTime}
        handleCheckboxChange={handleCheckboxChange}
        schedule={schedule.current}
        error={error}
        setError={setError}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          onClick={handleSubmit(handleSave)}
          loading={saveLocationLoading}
          label="Save"
        />
      </Box>
    </PageContent>
  );
};

export default AddLocation;
